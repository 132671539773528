// THIS FILE IS GENERATED AUTOMATICALLY DURING BUILD
// SHOULD NOT BE EDITED MANUALLY
import { version } from '../../../../../package.json';
export const versionInfo = {
  buildTime:'2024-08-30T08:55:00+02:00',
  gitHash:'d3b0fb5fa',
  branchName:'gpd',
  tag:'v1.3.65_gpd',
  version:version
};
